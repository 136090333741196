<template>
  <div class="bonus_profile">
    <div class="container fluid">

      <h1>{{ $localize('profile')['bonus']['title'] }}</h1>

      <div class="row align-items-center justify-content-between">
        <div class="col-xl-5 col-ml-5">
          <div class="bunus_card">
            <div class="left">
              <p>{{ $localize('profile')['bonus']['bonusCardTitle'] }}:</p>
              <pu-skeleton v-if="bonusBalance === null" tag="h3" height="20px" width="100px"></pu-skeleton>
              <h3 v-else>{{ $t('currency', { price: $numberFormat(bonusBalance) }) }}</h3>
            </div>

            <div class="right">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.01 10.4568C10.01 9.35777 10.901 8.46677 12 8.46677C13.099 8.46677 13.99 9.35777 13.99 10.4568C13.99 11.3478 13.441 12.0088 12.597 12.3558C12.209 12.5148 12 12.8558 12 12.8558M11.9982 15.5332L12 15.5302M19.998 11.999C19.998 16.4161 16.4171 19.997 12 19.997C7.58278 19.997 4.00195 16.4161 4.00195 11.999C4.00195 7.5818 7.58278 4.00098 12 4.00098C16.4171 4.00098 19.998 7.5818 19.998 11.999Z"
                  stroke="#fff"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

          </div>
        </div>
        <main-button
          class="btn_amount col-xl-2 col-ml-2 col-2"
          @click="showModal = true"
        >
          {{$localize("bonusTransfer")}}
        </main-button>
      </div>

      <h3>{{ $localize('profile')['bonus']['spend'] }}</h3>

      <p class="mb-12">{{ $localize('payServices')['mobile'] }}</p>

      <div v-if="!mobilePayServices.length" class="row">
        <pu-skeleton
          class="card-skeleton"
          v-for="i in 12"
          :key="i"
        />
      </div>

      <div v-else>
        <swiper class="swiper-wrapper" :options="swiperOptions">
          <swiper-slide class="swiper-slide" v-for="service in mobilePayServices" :key="service.id">
            <div class="swiper-card cursor-pointer" @click="openPay(service)">
              <img class="service-image" :src="service.img" :alt="service.title" />

              <div class="title">{{ service.title }}</div>
            </div>
          </swiper-slide>
        </swiper>

        <p v-if="otherPayServices.length > 0" class="mb-12 mt-25">{{ $localize('payServices')['other'] }}</p>

        <swiper v-if="otherPayServices.length > 0" class="swiper-wrapper" :options="swiperOptions">
          <swiper-slide class="swiper-slide" v-for="service in otherPayServices" :key="service.id">
            <div class="swiper-card cursor-pointer" @click="openPay(service)">
              <img class="service-image" :src="service.img" :alt="service.title" />

              <div class="title">{{ service.title }}</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

    </div>

    <my-overlay
      @closeOverlay="closeOverlay"
      :active="active || showModal"
    />

    <transition>
      <bonus-pay
        v-if="active"
        :bonus-balance="bonusBalance"
        :service="service"
        :active="active"
        @closeOverlay="closeOverlay"
      />
    </transition>

    <transition>
      <bonus-transfer
        v-if="showModal"
        :userCards="userCards"
        @closeOverlay="closeOverlay"
      />
    </transition>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';

import BonusPay from '@/components/profile/BonusPay.vue';
import MainButton from '@/components/buttons/MainButton';
import BonusTransfer from '@/components/profile/BonusTransfer';
import BuyerService from '@/services/buyer.service';
import MyOverlay from '@/components/UI/MyOverlay';

export default {
  name: 'bonusProfile',
  components: {
    MyOverlay,
    BonusTransfer,
    MainButton,
    BonusPay,
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    showModal: false,
    active: false,
    mobilePayServices: [],
    otherPayServices: [],
    bonusBalance: 0,
    service: {
      title: null,
      type: null,
      id: null,
    },
    swiperOptions: {
      slidesPerView: 6,
      spaceBetween: 32,
      freemode: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >= 576px
        576: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        1300: {
          slidesPerView: 4,
          spaceBetween: 32,
        },
        1600: {
          slidesPerView: 6,
          spaceBetween: 32,
        },
      },
    },
  }),
  computed: {
    userCards () {
      return this.$store.getters['buyers/cardUser'].filter( card => card.id !== 0)
    }
  },
  methods: {
    closeOverlay(){
      this.active = false
      this.showModal = false
      this.addClose(false)
    },
    openPay(service) {
      this.active = true;
      this.service = service;
    },
    addClose(status) {
      this.active = status;
      this.getBonusBalance();
    },
    async getPayServices() {
      const { data: response } = await this.$axios.get('buyer/pay-services/list');

      if (response.status === 'success') {
        this.mobilePayServices = response?.data.filter(payServices => payServices.type === 'mobile');
        this.otherPayServices = response?.data.filter(payServices => payServices.type === 'other');
      } else {
        response.response.message.forEach(error => this.$toastError(error.text));
      }
    },
    async getBonusBalance() {
      const { data: response } = await this.$axios.get('buyer/bonus-balance');
      if (response.status === 'success') {
        this.bonusBalance = response.data.bonus;
      }
    },
  },
  async created() {
    await this.getBonusBalance();
    await this.getPayServices();
    await BuyerService.cards();
  },
};
</script>

<style lang="scss" scoped>
  .bonus_profile {
    padding: 48px 44px;
    min-height: calc(100vh - 96px);
    background: $grey;
    width: 100%;

    h1 {
      font-weight: 900;
      font-size: 32px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 24px;
    }

    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.38px;
      color: $black;
      margin-top: 32px;
      margin-bottom: 16px;
    }

    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 133% */

      letter-spacing: 0.38px;

      /* black title */

      color: $black;
    }

    .service-image {
      width: 70px;
      height: 70px;

      @media (max-width: $mobile) {
        width: 40px;
        height: 40px;
      }
    }

    .bunus_card {
      background: url("../../assets/profile_assets/bonusCardBg.svg") no-repeat center center / cover;
      border-radius: 8px;
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 32px;

      p {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: $white;
        margin-bottom: 8px;
      }

      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.326451px;
        color: $white;
      }
    }
  }

  .swiper-bonus {
    padding-top: 15px;
    padding-bottom: 25px;
  }

  .swiper-card {
    text-align: center;
    padding-top: 33.33px;
    padding-bottom: 33.33px;
    transition: 0.3s;
    background: $white;
    border-radius: 8px;

    .title {
      margin-top: 15px;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.1px;
    }

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    }
  }

  @media (max-width: 767px) {
    .bonus_profile {
      padding: 27px 0px;
      margin-right: -16px;
      margin-left: -16px;
      width: auto;
    }
  }

  .card-skeleton{
    width: 260px;
    height: 180px;
    margin: 20px;
    border-radius: 10px;
  }
  .row{
    /*flex-wrap: wrap;*/
    /*justify-content: center;*/
  }

  .btn_amount{
    margin: 0 20px 32px;
    align-self: flex-end;
  }
</style>
