<template>
  <div class="bonus col-md-3 modal__active">
    <div class="form col-md-3" v-if="step === 1">
      <h4 class="form__title">{{ $localize('selectCard') }}</h4>
      <div class="cards" v-if="userCards.length > 0">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide v-for="(n, i) in userCards" :key="i">
            <div :data-id="n.id" class="card" :style="`background:url(${n.style}) no-repeat center center / cover;`"
              :class="n.theme">
              <div class="card_top">
                <div class="card_left">
                  <h3>
                    {{ n.title || $localize('profile')['cardTitleDefault'] }}
                  </h3>
                  <p>{{ $numberFormat(n.balance) }}</p>
                </div>
                <div class="card_right">
                  <div class="frame" :style="`background-image:url(${n.logo});`"></div>
                </div>
              </div>
              <div class="card_bottom">
                <div class="card_left">
                  {{ n.pan }}
                </div>
                <div class="card_right">
                  {{ n.exp }}
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <h4 v-else>Карточки не найдены</h4>
      <button class="btn__close" @click="$emit('closeOverlay')">&times;</button>
      <p class='commision__amount'>{{ $localize('commisionAmount') }} {{ $numberFormat(commisionAmount) }}</p>
      <div class="form__control">
        <label>{{ $localize('setPrice') }}</label>
        <ValidationProvider name="sum" v-slot="{ errors }" rules="required|min_value:1000">
          <input type="tel" v-mask="priceMask" class="form__input" v-model="amountVal" />
          <div class='error'>{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <main-button :isLoading="isLoading" :disabled="!formValid" @click="sendSMSCode">
        {{ $localize('button')['continue'] }}
      </main-button>
    </div>

    <div class="form col-md-3" v-else-if="step === 2">
      <h4 class="form__title">{{ $localize('sms-code')['title'] }} </h4>
      <button class="btn__close" @click="$emit('closeOverlay')">&times;</button>
      <p>{{ $localize('sms-code')['text'] }} {{ userInfo.phone }}</p>
      <sms-code-input v-model="smsCode" class="fields-container" :length="4" input-type="tel" pattern="[^0-9]+"
        :ignorePattern="false" fieldClass="custom-field-class" :size="32" :disabled="false" type="tel" />
      <p class="error" v-if="error">{{ error }}</p>
      <main-button :isLoading="isLoading" :disabled="!smsCodeValid" @click="confirmSMSCode">
        {{ $localize('button')['continue'] }}
      </main-button>
    </div>

    <div class="form col-md-3" v-else-if="step === 3">
      <button class="btn__close" @click="$emit('closeOverlay')">&times;</button>
      <h2 class="success">{{ success }}</h2>
      <img :src="successIcon" class="success__icon" alt="success" />
      <main-button @click="$emit('closeOverlay')">
        {{ $localize('button')['close'] }}
      </main-button>
    </div>
  </div>
</template>

<script>
import MainButton from '../buttons/MainButton'
import VueTypes from 'vue-types'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { createNamespacedHelpers } from 'vuex'
import successIcon from '@/assets/icons/success_green.svg';

const { mapGetters: mapBuyerGetters } = createNamespacedHelpers('buyers')
import SwiperCore, { Pagination } from 'swiper';
SwiperCore.use([Pagination]);

export default {
  components: { MainButton, Swiper, SwiperSlide },
  props: {
    userCards: VueTypes.array.def(null),
  },
  data: () => ({
    successIcon: successIcon,
    isLoading: false,
    success: 'success',
    step: 1,
    error: '',
    amountVal: '',
    smsCode: '',
    amountConfirmed: false,
    selectedCard: null,
    swiperOption: {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      slidesPerView: 2,
      spaceBetween: 32,
      grabCursor: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 576px
        576: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1300: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1600: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
    },
  }),
  watch: {
    amountVal(val) {
      this.amountVal = val.replace(/^0/gi, '')
    }
  },
  computed: {
    ...mapBuyerGetters(['userInfo']),
    priceMask() {
      let mask = '';

      switch (this.amountVal.length) {
        case 4:
          mask = '# ###';
          break;
        case 6:
          mask = '## ###';
          break;
        case 7:
          mask = '### ###';
          break;
        case 8:
          mask = '# ### ###';
          break;
        case 10:
          mask = '## ### ###';
          break;
        case 11:
          mask = '### ### ###';
          break;

        default:
          mask = '';
          break;
      }

      return mask;
    },
    amount() {
      return this.amountVal.replace(/\s/g, '')
    },
    commisionAmount() {
      return this.amount * 1.01
    },
    formValid() {
      return this.amount >= 1000
    },
    smsCodeValid() {
      return this.smsCode !== '' && this.smsCode.length === 4
    }
  },
  methods: {
    async sendSMSCode() {
      this.isLoading = true;
      document.querySelector('.bonus').querySelectorAll('.swiper-slide').forEach(card => {
        if (card.classList.contains('swiper-slide-active')) {
          this.selectedCard = card.firstChild.getAttribute('data-id')
        }
      })
      try {
        const { data } = await this.$axios.post('buyer/bonus-to-card', {
          card_id: this.selectedCard,
          bonus_sum_request: this.amount,
        }, {
          headers: {
            'Content-Language': this.$i18n.locale,
          },
        })
        this.isLoading = false
        if (data.status === 'success') {
          this.step++
        } else {
          this.$toastError(data.response.message[0])
        }

      } catch (err) {
        console.log(err)
      }
    },
    async confirmSMSCode() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.post('buyer/bonus-to-card-confirm', {
          sms_code: this.smsCode,
          card_id: this.selectedCard,
          buyer_id: this.userInfo.id,
          amount: this.amount,
        }, {
          headers: {
            'Content-Language': this.$i18n.locale,
          },
        })
        if (data.status === 'success') {
          this.smsCode = ''
          this.success = data.response.message[0];
          this.isLoading = false
          this.step++
        } else {
          this.$toastError(data.response.message[0])
          this.smsCode = ''
          this.isLoading = false
        }

      } catch (err) {
        console.log(err)
      }

    },
  },
};
</script>

<style lang="scss" scoped >
.bonus {
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 30px 15px;
  background-color: #fff;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  visibility: visible;

  &.v-enter-active,
  &.v-leave-active {
    transition: transform 500ms ease;
  }

  &.v-enter,
  &.v-leave-to {
    transform: translate(-50%, 100%);
    visibility: hidden;
  }
}

.success__icon {
  display: block;
  width: 100px;
  height: 100px;
  margin: 20px auto;
}

.success {
  color: #53DB8C;
  margin-bottom: 20px;
  text-align: center;
}

.commision__amount {
  margin: 10px 0;
  color: $main;
  font-size: 16px !important;
}

.form {
  width: 100%;

  &__title {
    text-align: center;
    font-size: 25px;
    padding-bottom: 15px;
  }

  &__control {
    display: flex;
    flex-direction: column;
    margin: 15px 0;

    p {
      font-size: 20px;
    }

    label {
      padding-bottom: 10px;
      font-size: 20px;
    }

    input {
      width: 100%;
      padding: 18px 5px;
      border: 1px solid #ccc;
      outline: none;

      &:focus {
        box-shadow: 0 0 3px $main;
        border: none;
        transition: all 300ms linear;
      }
    }
  }
}

.btn__close {
  position: absolute;
  top: -75px;
  right: -42px;
  background-color: transparent;
  font-size: 50px;
  font-weight: 700;
  border: none;
  color: $main;
  cursor: pointer;

  &:hover {
    transform: rotate(270deg);
    transition: all 300ms linear;
  }
}

.btn__retry {
  margin-top: 15px;
  background: rgba(102, 16, 245, 0.1);
  color: #6610F5;
  box-shadow: none;
}

p {
  margin-bottom: 15px;
  font-size: 20px;
}

.swiper-container {
  padding-bottom: 25px;
  margin-top: 16px;
}

.swiper-slide {
  .card {
    padding: 16px;
    border-radius: 8px;

    &.whiteThema {
      color: white;
    }

    .card_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 88px;

      .card_left {
        h3 {
          font-size: 16px;
          line-height: 19px;
        }

        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
        }
      }

      .card_right {
        .frame {
          width: 72px;
          height: 32px;
          background-repeat: no-repeat;
          background-position: top right;
          background-size: contain;
        }
      }
    }

    .card_bottom {
      display: flex;
      justify-content: space-between;

      .card_left {
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
      }

      .card_right {
        font-weight: bold;
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}

.active {
  border-color: $main  !important;
  transform: scale(.95);
  transition: all 100ms ease-in;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

@media (max-width: 768px) {

  p,
  label,
  button {
    font-size: 14px !important;
  }

  .btn__close {
    top: -40px;
    right: -15px;
    font-size: 40px !important;
  }
}
</style>
