<template>
  <ValidationObserver
    class="card_balance active"
    v-slot="{ handleSubmit, invalid }"
    ref="cardBalance"
  >
    <div class="top">
      <div class="icon">
        <img src="@/assets/profile_assets/diamondPay.svg" alt="" />
      </div>
      <a href="javascript:" class="close" @click="closeSidebar">
        <img src="@/assets/profile_assets/closeSidebar.svg" alt="" />
      </a>
    </div>

    <h3 class="mb-16" v-if="service.title">{{ service.title }}</h3>

    <div class="bunus_card" v-if="step === 1">
      <div class="left">
        <p>{{ $localize('profile')['bonus']['bonusCardTitle'] }}:</p>
        <h3>{{ $t('currency', { price: $numberFormat(bonusBalance) }) }}</h3>
      </div>

      <div class="right">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.01 10.4568C10.01 9.35777 10.901 8.46677 12 8.46677C13.099 8.46677 13.99 9.35777 13.99 10.4568C13.99 11.3478 13.441 12.0088 12.597 12.3558C12.209 12.5148 12 12.8558 12 12.8558M11.9982 15.5332L12 15.5302M19.998 11.999C19.998 16.4161 16.4171 19.997 12 19.997C7.58278 19.997 4.00195 16.4161 4.00195 11.999C4.00195 7.5818 7.58278 4.00098 12 4.00098C16.4171 4.00098 19.998 7.5818 19.998 11.999Z"
            stroke="#fff"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>

    <div class="form_input" v-if="step === 1 && service.type === 'mobile'">
      <label for="inp1" class="form-label">
        {{ $localize('sign-up')['form-label'] }}
      </label>
      <ValidationProvider
        v-slot="{errors}"
        name="phoneNumber"
        :rules="`required|phone|phone_type:${service.title}`"
        key="mobile"
      >
        <input
          placeholder="+998"
          v-model="phone"
          v-mask="'+998 (##) ###-##-##'"
          type="tel"
          id="inp1"
          class="form-control"
        />
        <div class='error'>{{ errors[0] }}</div>
      </ValidationProvider>
    </div>

    <div class="form_input" v-if="step === 1 && service.type === 'other'">
      <label for="login" class="form-label" >
        {{ $localize('BonusPay')['form-title'] }}
      </label>

      <ValidationProvider
        v-slot="{errors}"
        rules="required"
        name="login"
        key="other"
      >
        <input
          id="login"
          :placeholder="$localize('BonusPay')['form-title']"
          v-model="provider"
          class="form-control"
        />
        <div class='error'>{{ errors[0] }}</div>
      </ValidationProvider>

    </div>

    <div class="form_input" v-if="step == 1">
      <label for="inp2" class="form-label">
        {{ $localize('profile')['history']['sum'] }}
        <strong>{{ amount }}</strong>
      </label>
      <ValidationProvider
        name="sum"
        v-slot="{errors}"
        rules="required|min_value:1000"
      >
        <input
          type="tel"
          v-model="amount"
          v-mask="priceMask"
          id="inp2"
          class="form-control"
          :placeholder="$localize('sign-up')['sumPlaceholder']"
        />
        <div class='error'>{{ errors[0] }}</div>
      </ValidationProvider>
    </div>

    <div class="success" v-else>
      <img src="@/assets/profile_assets/success.svg" alt="" />
      <h3>{{ $localize('profile')['balanceUp']['successPaid'] }}</h3>
    </div>

    <main-button
      :is-loading="isLoading"
      @click="handleSubmit(pay)"
      :disabled="invalid"
    >
      {{ step === 1 ? $localize('button')['pay'] : $localize('button')['close'] }}
    </main-button>
  </ValidationObserver>
</template>

<script>
import VueTypes from 'vue-types';
import MainButton from '@/components/buttons/MainButton';
import { ValidationObserver } from 'vee-validate';
import validPhone from '@/helpers/validPhone'

export default {
  props: {
    active: VueTypes.bool,
    service: VueTypes.object.isRequired,
    bonusBalance: VueTypes.number.isRequired,
  },
  components: {
    MainButton,
    ValidationObserver
  },
  data: () => ({
    provider: '',
    phone: '',
    amount: '',
    step: 1,
  }),
  computed: {
    priceMask() {
      let mask = '';

      switch (this.amount.length) {
        case 4:
          mask = '# ###';
          break;
        case 6:
          mask = '## ###';
          break;
        case 7:
          mask = '### ###';
          break;
        case 8:
          mask = '# ### ###';
          break;
        case 10:
          mask = '## ### ###';
          break;
        case 11:
          mask = '### ### ###';
          break;

        default:
          mask = '';
          break;
      }

      return mask;
    },
    isLoading() {
      return this.$store.getters['common/getLoading'];
    },
  },
  watch: {
      amount(val) {
        this.amount = val.replace(/^0/gi, '')
      }
  },
  methods: {
    async pay() {
      const sum = parseInt(this.amount.replace(/\s/g, ''));
      if (this.step === 1) {
        const userAccount = this.service.type === 'mobile' ? `+${validPhone(this.phone)}` : this.provider;
        const { data: response } = await this.$axios.post('/buyer/pay-services/pay', {
          id: this.service.id,
          account: userAccount,
          amount: sum,
        },{
          headers: {
            'Content-Language': this.$i18n.locale,
          },
        });

        if (response.status === 'success') {
          this.step = 2;
        } else {
          response.response.message.forEach(({ text }) => this.$toastError(text));
        }
      } else {
        this.closeSidebar();
      }
    },
    closeSidebar() {
      this.$emit('closeOverlay');
    },
  },
};
</script>

<style lang="scss" scoped>
  .card_balance {
    padding: 40px 32px 32px 32px;
    max-width: 400px;
    background: $white;
    width: 400px;
    min-height: calc(100vh - 96px);
    position: fixed;
    top: 96px;
    right: 0;
    z-index: 1000;
    transform: translateX(0px);
    transition: 0.4s;

    &.v-enter,
    &.v-leave-active {
      transition: transform 500ms ease;
    }

    &.v-enter,
    &.v-leave-to {
      transform: translateX(500px);
    }

    .card-body {
      h3 {
        margin-top: 24px;
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: center;
        color: $black;
      }
    }

    .top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .icon {
        padding: 6px;
        background: rgba(102, 16, 245, 0.1);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 24px;
      }
    }

    .bunus_card {
      background: url("../../assets/profile_assets/bonusCardBg.svg") no-repeat center center / cover;
      border-radius: 8px;
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 32px;

      p {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: $white;
        margin-bottom: 8px;
      }

      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.326451px;
        color: $white;
      }
    }

    .form_input {
      margin-bottom: 32px;
      width: 100%;

      .form-label {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
      }

      .form-control {
        width: 100%;
        padding: 16px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $black;
        box-sizing: border-box;
        background: $grey;
        border-radius: 8px;
        border: none;
        transition: 0.4s;

        &::placeholder {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: rgba(30, 30, 30, 0.3);
        }

        &:focus {
          border: 1px solid #6610F5;
          outline: none;
          color: rgb(30, 30, 30);
        }
      }

      small {
        color: red;
      }
    }
  }

  .swiper-container {
    padding-bottom: 35px;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .swiper-slide {
    .card {
      padding: 16px;
      border-radius: 8px;

      &.whiteThema {
        color: white;
      }

      .card_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 88px;

        .card_left {
          h3 {
            font-size: 16px;
            line-height: 19px;
          }

          p {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
          }
        }

        .card_right {
          .frame {
            width: 72px;
            height: 32px;
            background-repeat: no-repeat;
            background-position: top right;
            background-size: contain;
          }
        }
      }

      .card_bottom {
        display: flex;
        justify-content: space-between;

        .card_left {
          font-weight: bold;
          font-size: 14px;
          line-height: 28px;
        }

        .card_right {
          font-weight: bold;
          font-size: 14px;
          line-height: 28px;
        }
      }
    }
  }

  button.p-button {
    cursor: pointer;
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 8px;
    width: 100%;
    padding: 18px 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: 0.2s;
    border: none;

    &:hover {
      background: $main-light;
    }
  }

  .btn-loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;

    &:after {
      animation: spinAround 500ms infinite linear;
      border: 2px solid $white;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
    }
  }

  .success {
    text-align: center;
    margin-top: 50px;

    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: $black;
      margin-top: 28px;
      margin-bottom: 40px;
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 575px) {
    .card_balance {
      padding: 16px;
      padding-top: 36px;
      max-width: 100%;
      width: 100%;
      left: 0;
      bottom: 0;
      top: auto;
      transform: translateX(0px);
      transform: translateY(2000px);
      overflow: auto;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      min-height: auto;

      &::after {
        content: "";
        display: block;
        position: absolute;
        background: $black;
        opacity: 0.3;
        border-radius: 4px;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
        width: 56px;
        height: 4px;
      }

      &.active {
        transform: translateY(0px);
      }

      .top {
        display: none;
      }

      .card-body {
        h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.38px;
          margin-bottom: 8px;
        }
      }

      ul.toggle {
        margin-bottom: 12px;

        li {
          a {
            padding: 11px 12px;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.154px;
          }
        }
      }

      .form_input {
        margin-bottom: 16px;

        .form-control {
          padding: 10px 16px;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;

          &::placeholder {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
          }
        }
      }

      .info-card {
        padding: 12px 10px;

        .icon {
          img {
            width: 20px;
            height: 20px;
          }
        }

        .info_title {
          h3 {
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.01em;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.01em;
          }
        }
      }
    }
    .swiper-container {
      margin-top: 12px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 400px) {
    .swiper-slide {
      .card {
        .card_top {
          margin-bottom: 60px;

          .card_left {
            h3 {
              font-size: 14px;
              line-height: 16px;
            }

            p {
              font-weight: bold;
              font-size: 19px;
              line-height: 23px;
            }
          }
        }

        .card_bottom {
          .card_left {
            font-size: 15px;
            line-height: 28px;
          }

          .card_right {
            font-size: 15px;
            line-height: 28px;
          }
        }
      }
    }
  }
  .error {
    font-size: 14px;
    color: red;
  }
</style>
